import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import * as React from 'react';
import PageLayout from '../components/common/page-layout/page-layout';
import Privacy from '../components/content/privacy/privacy';
import SectionIntended from '../components/templates/section-intended/section-intended';

const PrivacyPage = () => {
    const intl = useIntl();

    return (
        <PageLayout title={intl.formatMessage({ id: 'pages.privacy.title' })}>
            <SectionIntended>
                <h3 className="color-white" style={{ marginTop: '10px' }}>
                    <FormattedMessage id="pages.privacy.headline" />
                </h3>
                <p>
                    <a href="#uc-corner-modal-show" className="color-white">
                        Privatsphäre-Einstellungen
                    </a>
                </p>
                <Privacy />
            </SectionIntended>
        </PageLayout>
    );
};

export default PrivacyPage;
